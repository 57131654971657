import request from '@/utils/request'


export function homeResult(query) {
  return request({
    url: '/statistics/home/home',
    method: 'get',
    params: query
  })
}

export function homeAllResult(query) {
  return request({
    url: '/statistics/home/home/all',
    method: 'get',
    params: query
  })
}

/*广告*/
export function advertResult(query) {
  return request({
    url: '/statistics/home/advert',
    method: 'get',
    params: query
  })
}

/*商品分享*/
export function goodsResult(query) {
  return request({
    url: '/statistics/home/goods',
    method: 'get',
    params: query
  })
}
