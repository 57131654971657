import request from '@/utils/request'


export function orderAmountChart(query) {
  return request({
    url: '/statistics/order/order/amount',
    method: 'get',
    params: query
  })
}
export function orderCancelChart(query) {
  return request({
    url: '/statistics/order/order/cancel',
    method: 'get',
    params: query
  })
}

export function orderRefundChart(query) {
  return request({
    url: '/statistics/order/order/refund',
    method: 'get',
    params: query
  })
}

export function orderTimeChart(query) {
  return request({
    url: '/statistics/order/order/time',
    method: 'get',
    params: query
  })
}
export function orderNationChart(query) {
  return request({
    url: '/statistics/order/order/nation',
    method: 'get',
    params: query
  })
}




